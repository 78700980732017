<template>
  <div class="auto-submit">
    <!-- 主要工具页面 -->
    <div class="container">
      <div class="header">
        <h1 style="font-size: 20px;">自动建议提交工具</h1>
      </div>
      <div class="content">
        <div class="description">
          <el-alert
            title="工具说明"
            type="info"
            description="此工具用于自动遍历部门列表并提交建议，以防止接口失效。每个所选部门会创建一个标题为'自动创建需求（防接口失效）'的建议。"
            show-icon
            :closable="false"
          ></el-alert>
        </div>

        <div class="settings">
          <h3 style="margin-top: 20px;">配置</h3>
          <el-form label-width="120px">
            <el-form-item label="执行频率">
              <el-select v-model="frequency" placeholder="请选择执行频率">
                <!-- <el-option label="每15天一次" value="15days"></el-option>
                <el-option label="每两周一次（周日中午12点）" value="2weeks"></el-option> -->
                <el-option label="手动执行" value="manual"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="需求标题">
              <el-input v-model="title" placeholder="自动创建需求（防接口失效）"></el-input>
            </el-form-item>

            <el-form-item label="创建人">
              <el-input v-model="creator" placeholder="系统"></el-input>
            </el-form-item>

            <el-form-item label="选择部门">
              <div style="margin-bottom: 10px;">
                <el-button size="small" type="primary" @click="selectAllDepartments">全选</el-button>
                <el-button size="small" @click="unselectAllDepartments">取消全选</el-button>
              </div>
              <el-checkbox-group v-model="selectedDepartments">
                <el-checkbox
                  v-for="dept in categoryList"
                  :key="dept.workspace_id"
                  :label="dept.workspace_id"
                >
                  {{ dept.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>

        <div class="action-buttons">
          <el-button type="primary" @click="startSubmit" :loading="isSubmitting">开始执行</el-button>
          <el-button @click="clearLog">清空日志</el-button>
        </div>

        <div class="log">
          <h3>执行日志</h3>
          <div class="log-container" ref="logContainer" v-html="log"></div>
        </div>
      </div>
      <div class="footer">
        © {{ new Date().getFullYear() }} 自动建议提交工具 | 技术支持: 云创新部门
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AutoSubmit",
  data() {
    return {
      frequency: "manual",
      title: "自动创建需求（防接口失效）",
      creator: "系统",
      log: "",
      isSubmitting: false,
      categoryList: [],
      selectedDepartments: [], // 已选择的部门IDs
      timer: null
    };
  },
  methods: {
    async loadCategoryList() {
      try {
        this.addLog("正在加载部门列表...");
        // 使用全局配置或者请求获取部门列表
        if (
          window.globalData &&
          window.globalData.config &&
          window.globalData.config.categoryList
        ) {
          this.categoryList = window.globalData.config.categoryList;
          this.addLog(`成功加载部门列表，共 ${this.categoryList.length} 个部门`);
          
          // 默认全选部门
          this.selectedDepartments = this.categoryList.map(dept => dept.workspace_id);
        } else {
          // 如果没有全局配置，可以尝试从服务器获取
          const response = await axios.get("/api/config/categoryList");
          this.categoryList = response.data;
          this.addLog(`成功加载部门列表，共 ${this.categoryList.length} 个部门`);
          
          // 默认全选部门
          this.selectedDepartments = this.categoryList.map(dept => dept.workspace_id);
        }
      } catch (error) {
        this.addLog(`加载部门列表失败: ${error.message}`, "error");
      }
    },

    selectAllDepartments() {
      this.selectedDepartments = this.categoryList.map(dept => dept.workspace_id);
    },

    unselectAllDepartments() {
      this.selectedDepartments = [];
    },

    async submitOpinion(department) {
      try {
        this.addLog(`正在为部门 [${department.name}] 提交建议...`);

        // 构建表单数据
        const form = {
          department_id: department.workspace_id,
          department: department.workspaceName || department.name,
          // 选择第一个子类
          category: department.secondList[0].id,
          // 标题
          title: this.title,
          // 描述
          text: `这是系统定期创建的需求，用于防止接口失效。频率：每15天创建一次（或每两周一次，每次在周日中午12点）`,
          content: `这是系统定期创建的需求，用于防止接口失效。频率：每15天创建一次（或每两周一次，每次在周日中午12点）`,
          creator: {
            id: "system",
            name: this.creator
          },
          filelist: [],
          piclist: []
        };

        // 发送POST请求
        const response = await axios.post("/api/robot/send", {
          form: form,
          webhook: department.url
        });

        if (response.status === 200) {
          this.addLog(`为部门 [${department.name}] 提交建议成功!`, "success");
          return true;
        } else {
          this.addLog(
            `为部门 [${department.name}] 提交建议失败: ${response.statusText}`,
            "error"
          );
          return false;
        }
      } catch (error) {
        this.addLog(
          `为部门 [${department.name}] 提交建议时出错: ${error.message}`,
          "error"
        );
        return false;
      }
    },

    async startSubmit() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;
      this.clearLog();

      try {
        // 获取选中的部门对象列表
        const departmentsToSubmit = this.categoryList.filter(dept => 
          this.selectedDepartments.includes(dept.workspace_id)
        );

        this.addLog(`找到 ${departmentsToSubmit.length} 个部门需要提交建议`);

        if (departmentsToSubmit.length === 0) {
          this.addLog("请至少选择一个部门", "warning");
          this.isSubmitting = false;
          return;
        }

        // 逐个提交
        for (const department of departmentsToSubmit) {
          await this.submitOpinion(department);
          // 等待1秒，避免请求过于频繁
          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        this.addLog("自动提交建议完成!", "success");
      } catch (error) {
        this.addLog(`执行出错: ${error.message}`, "error");
      } finally {
        this.isSubmitting = false;
      }
    },

    addLog(message, type = "info") {
      const timestamp = new Date().toLocaleTimeString();
      let colorClass = "";

      if (type === "error") {
        colorClass = "color: red;";
      } else if (type === "success") {
        colorClass = "color: green;";
      } else if (type === "warning") {
        colorClass = "color: orange;";
      }

      this.log += `<div style="${colorClass}">[${timestamp}] ${message}</div>`;
      this.$nextTick(() => {
        if (this.$refs.logContainer) {
          this.$refs.logContainer.scrollTop = this.$refs.logContainer.scrollHeight;
        }
      });
    },

    clearLog() {
      this.log = "";
    },

    setupAutoRun() {
      // 清除之前的定时器
      if (this.timer) {
        clearInterval(this.timer);
      }

      if (this.frequency === "manual") return;

      const checkAndRun = () => {
        const now = new Date();

        if (this.frequency === "15days") {
          // 检查是否是15天的整数倍
          const startDate = new Date("2023-01-01");
          const daysSinceStart = Math.floor(
            (now - startDate) / (1000 * 60 * 60 * 24)
          );

          if (daysSinceStart % 15 === 0) {
            this.startSubmit();
          }
        } else if (this.frequency === "2weeks") {
          // 检查是否是周日中午12点
          const dayOfWeek = now.getDay(); // 0是周日
          const hour = now.getHours();

          if (dayOfWeek === 0 && hour === 12) {
            this.startSubmit();
          }
        }
      };

      // 每小时检查一次
      this.timer = setInterval(checkAndRun, 60 * 60 * 1000);

      // 立即检查一次
      checkAndRun();
    }
  },
  watch: {
    frequency() {
      this.setupAutoRun();
    }
  },
  mounted() {
    // 组件挂载后立即加载部门列表
    this.loadCategoryList();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>

<style scoped>
.auto-submit {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebeef5;
}

.content {
  margin-bottom: 20px;
}

.footer {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ebeef5;
  color: #909399;
  font-size: 12px;
}

.log-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f7fa;
  border-radius: 4px;
  height: 300px;
  overflow-y: auto;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-all;
}

.action-buttons {
  text-align: center;
  margin: 20px 0;
}
</style> 