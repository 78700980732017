import Vue from 'vue'
import "./assets/theme/style.css";
import ElementUI from 'element-ui';
import App from './App.vue'

import TDesign from 'tdesign-vue';
// 引入组件库全局样式资源
import 'tdesign-vue/es/style/index.css';


// import Message from './page/message/message.vue';
// import Intro from './page/intro/intro.vue';
import Auth from './page/auth/auth.vue';
// import Robot from './page/robot/robot.vue';
import Story from './page/story/story.vue';
import Mine from './page/mine/mine.vue';
import All from './page/all/all.vue';
import AutoSubmit from './page/auto-submit/auto-submit.vue';


import vueRouter from 'vue-router';

import Bus from "./eventbus";

Vue.use(Bus);

window.history.replaceState(null, null, window.location.pathname);

Vue.prototype.$EventBus = new Vue()

Vue.config.productionTip = false;
Vue.use(vueRouter);
Vue.use(ElementUI);
Vue.use(TDesign);


new Vue({
  router: new vueRouter({
    routes: [
      {
        path: '/',
        redirect: '/story'
      },
      // {
      //   name: 'intro',
      //   path: '/intro',
      //   components: {
      //     default: Intro,
      //   },
      // },
      {
        name: 'auth',
        path: '/auth',
        components: {
          default: Auth,
        },
      },
      // {
      //   name: 'message',
      //   path: '/message',
      //   components: {
      //     default: Message,
      //   },
      // },    
      // {
      //   name: 'robot',
      //   path: '/robot',
      //   components: {
      //     default: Robot
      //   }
      // },
      {
        name: 'story',
        // path: '/story',
        path: '/story',
        components: {
          default: Story
        }
      },
      {
        name: 'edit',
        path: '/story/:id',
        components: {
          default: Story
        }
      },
      {
        name: 'mine',
        path: '/mine',
        components: {
          default: Mine
        }
      },
      {
        name: 'all',
        path: '/all/:type',
        components: {
          default: All
        }
      },
      {
        name: 'auto-submit',
        path: '/auto-submit',
        components: {
          default: AutoSubmit
        }
      }      
    ]
  }),
  render: h => h(App),
}).$mount('#app')
